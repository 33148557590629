document.addEventListener("DOMContentLoaded", function() {

  const accordions = document.querySelectorAll('.accordion');
  if(accordions) {
      accordions.forEach((accordion) => {
          accordion.addEventListener('click', () => {
              accordion.classList.toggle('active');

              const panel = accordion.nextElementSibling;

              if (panel.style.maxHeight) {
                  panel.style.maxHeight = null;
              } else {
                  panel.style.maxHeight = `${panel.scrollHeight}px`;
              }
          });
      });
  }


    let swiperBlock = document.querySelector('.mySwiper')
    if(swiperBlock) {
        let clientWidth = window.innerWidth
        if(window.innerWidth <= 992) {
            console.log(clientWidth)
            var swiper = new Swiper(".mySwiper", {
                slidesPerView: 1.2,
                spaceBetween: 16,
                pagination: {
                    el: ".swiper-pagination",
                },
                breakpoints: {
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 2,

                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 3.2,
                    },
                    750: {
                        slidesPerView: 4,
                    },
                    // 992: {
                    //     touchRatio: 0,
                    // },
                },
            });
        }
    }

  let hamburger = document.querySelector('.hamburger')
  let openHamburger = document.querySelector('.header--modal')
  let closeHamburger = document.querySelector('.hamburger--close')

    openHamburger.addEventListener('click',function () {
        hamburger.classList.add('active')
        console.log('1')
    })
    closeHamburger.addEventListener('click',function () {
        hamburger.classList.remove('active')
        console.log('2')
    })


    let discountPopup = document.querySelector('.discount--popup')
    if(discountPopup) {
        let openYoutube = document.querySelector('.openYoutube')
        let closeYoutube = document.querySelector('.closeYoutube')
        openYoutube.addEventListener('click',function () {
            discountPopup.classList.add('active')
        })
        closeYoutube.addEventListener('click',function () {
            discountPopup.classList.remove('active')
        })
    }

    let helpPopup = document.querySelector('.help--popup')
    if(helpPopup) {
        let helpPopupClosed = document.querySelector('.help--popup--closed')
        helpPopupClosed.addEventListener('click',function (){
            helpPopup.classList.remove('active')
        })
        let helpPopupOpen = document.querySelector('.openHelpPopup')
        helpPopupOpen.addEventListener('click',function (){
            helpPopup.classList.add('active')
        })

    }

    let mainPopup = document.querySelector('.main--popup')
    if(mainPopup) {
        document.querySelector('.main--popup--closed').addEventListener('click',function (){
            mainPopup.classList.remove('active')
        })
    }
});
